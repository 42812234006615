// import { createRouter, createWebHistory } from "vue-router";
import App from "@/App.vue";
import HelloWorld from "@/components/HelloWorld.vue";
import Home from "@/components/Home.vue";
import LandingPage from "@/components/LandingPage.vue";
import RegisterUser from '@/components/RegisterUser.vue';
export const routes = [
  { path: "/", component: App,meta: {
    requiresAuth: false,
  }, },
  { path: "/landing-page",
    name:"landingpage",
    component: LandingPage,
    meta: {
      requiresAuth: false,
    },
  },
  { path: "/hello",
    component: HelloWorld,
    meta: {
      requiresAuth: false,
    },
  },
  { path: "/home", 
    name:"Home",
    component: Home,
    meta: {
      requiresAuth: true,
    }, 
  },
  { 
    path: "/register",
    name:"register",
    component: RegisterUser,
    meta: {
      requiresAuth: false,
    }, 
  }
];
